body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#rootContainer {
  padding: 0;
}

.txtInput {
  background-color: white!important;
}

#dispatchRoot {
  /* background-color: "#FDF8F4"!important; */
}

#root {
  background-color: #F7F7F7;
}
.required:after {
    content:" *"!important;
    color: red!important;
  }